/* You can add global styles to this file, and also import other style files */
/* @import "~@angular/material/prebuilt-themes/indigo-pink.css"; */
/* @import "~@/src/custom-material-theme.scss"; */

@import "~ngx-ui-switch/ui-switch.component.css";
@import "~animate.css/animate.css";
@import "~@ctrl/ngx-emoji-mart/picker";
@font-face {
  font-family: disks;
  src: url('assets/fonts/disks.ttf');
}

body {
  font-family: "Work Sans", sans-serif;
  color: #555;
  background: #f5f5f5;
}

body [class^="mat-"],
body [class*=" mat-"] {
  font-family: "Work Sans", sans-serif;
}

body .material-icons {
  font-family: "Material Icons";
}

html,
body {
  height: 100%;
}

.disk-font {
  font-family: disks !important;
}

/* Chrome's Blue Highlighting disable */

/* input:focus {
  box-shadow: none;
} */

.env-text {
  color: #d00;
  margin: 0px 10px;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
}

.animated.very-fast {
  animation-duration: 0.3s;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

/* BRAND COLORS */
.brand-primary-color {
  color: #1174bc;
}

.brand-secondary-color {
  color: #7fb539;
}

.brand-primary-bg {
  background-color: #1174bc;
}

.brand-secondary-bg {
  background-color: #7fb539;
}
/* BRAND COLORS */

button {
  cursor: pointer;
}

.font-inherit {
  font: inherit;
}

.component-container {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.component-container .component-container-content {
  padding: 10px;
  /* overflow: auto; */
}

.component-container .component-sidebar {
  flex: 0 0 200px;
  overflow: auto;
  border-radius: 0px;
  margin: 0px;
  border-top: none;
}

.component-container .component-sidebar.right-component-sidebar {
  flex: 0 0 350px;
}

.component-container .header {
}

.component-container .main-section {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.component-container .footer {
}

.bg-dashboard {
  /* font-size: 14px; */
  text-decoration: none !important;
  color: #2980b9;
  background: RGBA(17, 116, 188, 0.2);
}

.mr-container {
  /* min-height: 100%; */
  overflow: auto;
  padding: 10px;
  padding-bottom: 45px;
  /* background: #F5f5f5; */
}

.mr-container-negative {
  margin: -10px -10px -45px;
}

@media screen and (min-width: 600px) {
  .mr-container {
    padding: 10px 50px;
    padding-bottom: 45px;
  }

  .mr-container-negative {
    margin: -10px -50px -45px;
  }
}

.main-section {
  overflow: auto;
}

.intl-tel-input {
  width: 100%;
}

.light-font {
  font-weight: lighter;
}

.cursor-pointer {
  cursor: pointer;
}

.badge.bg-circle {
  display: inline-block;
  padding: 6px;
  vertical-align: text-bottom;
}

ngx-datatable.ngx-datatable.mr-table {
  border-top: 5px solid rgb(17, 116, 188);

  /* mat-elevation-z1 */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

@keyframes mr-spinner {
  to {
    transform: rotate(360deg);
  }
}

.mr-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #07d;
  border-bottom-color: #07d;
  animation: mr-spinner 0.8s ease infinite;
}

.mr-spinner-white.mr-spinner:before {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

::ng-deep .cdk-overlay-connected-position-bounding-box {
  z-index: 10000 !important;
}

::ng-deep .cdk-global-overlay-wrapper {
  z-index: 1000 !important;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 2000 !important;
}

.mr-ripple {
  cursor: pointer;
  text-align: center;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  -webkit-user-drag: none;
  -webkit-tap-highlight-color: transparent;
}

.flex-spacer {
  flex: 1 1 auto;
}

.assigned-members-modal .mat-dialog-container {
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}
