// Your variable overrides

// $white:    #fff !default;
// $gray-100: #f8f9fa !default;
// $gray-200: #ecf0f1 !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #b4bcc2 !default;
// $gray-600: #95a5a6 !default;
// $gray-700: #7b8a8b !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;
// $black:    #000 !default;

$blue:    #2980b9 !default;
// $indigo:  #a29bfe !default;
// $purple:  #6c5ce7 !default;
// $pink:    #fd79a8 !default;
$red:     #e74c3c !default;
// $orange:  #e17055 !default;
// $yellow:  #fdcb6e !default;
$green:   #27ae60 !default;
// $teal:    #00cec9 !default;
// $cyan:    #3498DB !default;

$primary:       $blue !default;
// $secondary:     #00b5ad !default;
$success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
$danger:        $red !default;
// $light:         $gray-200 !default;
// $dark:          $gray-700 !default;

$enable-gradients: false;

$font-family-sans-serif:      'Work Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;


$font-size-base: .875rem;
$font-size-sm: .675rem;
$font-size-lg: 1rem;

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";
