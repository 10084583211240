/* For use in src/lib/core/theming/_palette.scss */
$md-mrblue: (
    50 : #e2eef7,
    100 : #b8d5eb,
    200 : #88bade,
    300 : #589ed0,
    400 : #3589c6,
    500 : #1174bc,
    600 : #0f6cb6,
    700 : #0c61ad,
    800 : #0a57a5,
    900 : #054497,
    A100 : #c3d9ff,
    A200 : #90b9ff,
    A400 : #5d99ff,
    A700 : #4389ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-mrgreen: (
    50 : #f0f6e7,
    100 : #d9e9c4,
    200 : #bfda9c,
    300 : #a5cb74,
    400 : #92c057,
    500 : #7fb539,
    600 : #77ae33,
    700 : #6ca52c,
    800 : #629d24,
    900 : #4f8d17,
    A100 : #deffc3,
    A200 : #c1ff90,
    A400 : #a4ff5d,
    A700 : #96ff44,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-mrred: (
    50 : #faeae0,
    100 : #f2ccb3,
    200 : #e9aa80,
    300 : #e0874d,
    400 : #da6e26,
    500 : #d35400,
    600 : #ce4d00,
    700 : #c84300,
    800 : #c23a00,
    900 : #b72900,
    A100 : #ffe5e0,
    A200 : #ffbaad,
    A400 : #ff8f7a,
    A700 : #ff7961,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-mryellow: (
    50 : #fef5e7,
    100 : #fce6c2,
    200 : #fad59a,
    300 : #f8c472,
    400 : #f7b853,
    500 : #f5ab35,
    600 : #f4a430,
    700 : #f29a28,
    800 : #f09122,
    900 : #ee8016,
    A100 : #ffffff,
    A200 : #fff4eb,
    A400 : #ffd8b8,
    A700 : #ffcb9e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

